import { PropsWithChildren } from "react";
import { forwardRef } from "@chakra-ui/react";
import { Button } from "src/components/base/button";
import { IBtnColor, ghostColorMap } from "src/theme/components/button/button";

const extendedGhostColorMap: { [key: string]: IBtnColor } = {
    ...ghostColorMap,
    blush: {
        bg: "transparent",
        color: "blush.700",
        _hover: {
            bg: "blush.50",
        },
        _active: {
            bg: "blush.200",
        },
        _disabled: {
            bg: "transparent",
            color: "blush.300",
        },
    },
};

interface ITagButton extends PropsWithChildren {}

export const TagButton = forwardRef<ITagButton, typeof Button>(({ colorScheme = "gray", children, ...buttonProps }, ref) => (
    <Button
        ref={ref}
        variant="outline"
        size="xs"
        rounded="16px"
        sx={{
            ...extendedGhostColorMap[colorScheme],
        }}
        {...buttonProps}
    >
        {children}
    </Button>
));
